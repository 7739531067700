.alert {
    color: white;
    border-radius: 10px;
    position: fixed;
    top: 50px;
    right: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    z-index: 1111;
}
.alert p {
    margin: 0;
}
.alert-success {
    background: var(--success-green);
}
.alert-error {
    background: var(--error-red);
}
.close {
    color: white;
    cursor: pointer;
    margin-right: 10px;
}