:root {
  --rundvirke-light-green: #5F9644;
  --rundvirke-dark-green: #1C7446;
  --rundvirke-saturated-green: #a9c79a;
  --rundvirke-superlight: #bfbfbf;
  --rundvirke-light-grey: #737674;
  --rundvirke-dark-grey: #1B1B1B;
  --rundvirke-dark-grey: #1B1B1B;
  --rundvirke-blackground: #292929;
  --error-red: #E8101B;
  --success-green: #5F9644;
}

a:hover {
  text-decoration: none !important;
}

.error-msg {
  color: var(--error-red);
  margin: 0 !important;
  padding: 0.5px 0;
  font-size: 1.1em;
  font-style: bold;
  animation-name: animate-error-msg;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes animate-error-msg {
  from {
    background-color: var(--error-red);
    color: #000
  }

  to {
    background-color: transparent;
    color: var(--error-red)
  }
}

.success-msg {
  color: var(--success-green);
  margin: 0 !important;
  padding: 0.5px 0;
  font-size: 1.1em;
  font-style: bold;
  animation-name: animate-success-msg;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes animate-success-msg {
  from {
    background-color: var(--success-green);
    color: #000
  }

  to {
    background-color: transparent;
    color: var(--success-green)
  }
}

footer {
  background-color: white;
}

.stickyrow {
  position: sticky;
  bottom: 0;
  min-width: 100%;
  display: block !important;
}

.mini-table {
  white-space: nowrap;
}

.mini-table td, .mini-table th {
  padding: 16px 8px;
}

.mini-table td:first-of-type, .mini-table th:first-of-type {
  padding-left: 16px;
}

.mini-table td:last-of-type {
  text-align: right;
}

.mini-table.avlagg th:first-of-type,
.mini-table.avlagg td:first-of-type {
  width: 40%;
}


.mini-table.avlagg th:last-of-type,
.mini-table.avlagg td:last-of-type {
  width: 105px;
}

.minitablefooter {
  position: sticky;
  bottom: 0;
  background-color: white;
}

.minitablefooter td {
  padding: .2rem 5px;
}

.minitablefooter td:last-of-type {
  padding-right: 16px;
}

.minitablefooter .input-field {
  display: grid;
  grid: 1fr / 30% repeat(3, 1fr);
  gap: 1rem;
}

.minitablefooterVO .input-field .compactinput {
  grid-column: 1 / 4;
}

.compactinput {
  padding: 0.375rem 0.2rem;
  border-radius: 0.015px !important;
}

.tabort {
  margin-right: 32%;
  float: right;
}

.CircelKnappVO {
  /* margin-right: 10%; */
  margin-right: 29px;
  float: right;
  margin-top: 9px;
  /* vertical-align: 'middle' */
}

.CircelKnapp {
  margin-right: 45%;
  margin-top: 9px;
  vertical-align: 'middle'

}

.inputt {
  width: 18%;
  float: right;
  margin-right: 10%;
  padding-top: 7px;
  margin-top: 7px;
}

.inputt1 {
  width: 18%;
  float: right;
  margin-right: 15%;
  padding-top: 7px;
  margin-top: 7px;
}

.virkesorder {
  margin-left: 10px;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.popupHeaderContainer {
  display: grid;
  grid-template-columns: 90% 10%;
  grid-gap: 5px;
  max-width: 400px;
}

.popupHeaderName {
  width: 100%;
  max-width: 400px;
}

.dashboard-item {
  color: black;
  font-weight: bold;
}
.dashboard-item:hover {
  color: var(--rundvirke-dark-green);
}

.clickable-item {
  color: var(--rundvirke-light-green);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.clickable-item:hover {
  color: var(--rundvirke-saturated-green);
}

.unclickable-item {
  color: var(--rundvirke-light-grey);
  font-weight: bold;
}

@media screen and (max-width: 1349px) {
  .tabort {
    margin-right: 32%;
  }

  .CircelKnappVO {
    margin-right: 23%;
    float: right;
    margin-top: 9px;
  }

  .CircelKnapp {
    margin-right: 70%;
    float: right;
    margin-top: 9px;
  }
}

@media screen and (max-width: 1550px) and (min-width:1350px) {
  .tabort {
    margin-right: 32%;
  }

  .CircelKnappVO {
    margin-right: 19%;
    float: right;
    margin-top: 9px;
  }

  .CircelKnapp {
    margin-right: 66%;
    float: right;
    margin-top: 9px;
  }
}

select.filtered {
  outline: 3px solid var(--rundvirke-light-green) !important;
  outline-offset: 2px;
}

/* Custom xxl devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.666666%;
    max-width: 41.666666%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.666666%;
    max-width: 91.666666%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

body > div > .fade {
  transition: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    cursor: pointer;
}